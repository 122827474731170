var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 col-md-12"
  }, [_c('div', {
    staticClass: "ticket-form-wrap login-form"
  }, [_c('div', {
    staticClass: "auth-form pt-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h2', [_vm._v("Submit An Issue")]), _c('div', [_c('router-link', {
    staticClass: "go-home-btn",
    attrs: {
      "to": "/"
    }
  }, [_c('i', {
    staticClass: "fa fa-home me-2"
  }), _vm._v("Go To Home")])], 1)]), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-lg-6 col-md-12 col-sm-12 mb-3"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "first_name",
            "labelName": "First Name",
            "inputName": "first_name",
            "vmodel": _vm.form.first_name,
            "input-class": "shadow-none"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "first_name", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "col-lg-6 col-md-12 col-sm-12 mb-3"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "last_name",
            "labelName": "Last Name",
            "inputName": "last_name",
            "vmodel": _vm.form.last_name,
            "input-class": "shadow-none",
            "form-group-class": "w-full"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "last_name", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-lg-6 col-md-12 col-sm-12 mb-3"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true,
              email: true
            },
            "labelFor": "email",
            "labelName": "Email",
            "inputName": "email",
            "vmodel": _vm.form.email,
            "input-class": "shadow-none"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "email", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "col-lg-6 col-md-12 col-sm-12 mb-3"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "phone",
            "labelName": "Phone Number",
            "inputName": "phone",
            "vmodel": _vm.form.phone,
            "input-class": "shadow-none"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "phone", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-lg-6 col-md-12 col-sm-12 mb-3"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "subject",
            "labelName": "Subject",
            "inputName": "subject",
            "vmodel": _vm.form.subject,
            "input-class": "shadow-none"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "subject", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "col-lg-6 col-md-12 col-sm-12 mb-3"
        }, [_c('input-multi-select', {
          attrs: {
            "vmodel": _vm.form.category_id,
            "label-for": "category",
            "labelName": "Category",
            "options": _vm.categories.length ? _vm.categories.map(function (item) {
              return item.id;
            }) : [],
            "custom-label": _vm.categories.length ? function (id) {
              return _vm.categories.find(function (item) {
                return item.id == id;
              }).name;
            } : function () {},
            "active-data": _vm.categories,
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "category_id", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-lg-12 col-md-12 col-sm-12 mb-3"
        }, [_c('input-quill-editor', {
          attrs: {
            "vmodel": _vm.form.message,
            "label": "Message",
            "rules": {
              required: true
            },
            "placeholder": "Add your message here ..."
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "message", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-lg-12 col-md-12 col-sm-12 mb-3"
        }, [_c('input-file-multiple', {
          attrs: {
            "vmodel": _vm.form.issue_document,
            "label-name": "Upload Document",
            "label-for": "document",
            "rules": {
              required: false
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "issue_document", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "labelMessage",
            fn: function fn() {
              return [_c('span', {
                staticClass: "upload-message",
                attrs: {
                  "id": "ticket-upload-files"
                }
              }, [_c('i', {
                staticClass: "fa fa-exclamation"
              }), _c('b-popover', {
                attrs: {
                  "target": "ticket-upload-files",
                  "placement": "top",
                  "triggers": "hover focus",
                  "content": "Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt up to 4Mb",
                  "id": "ticket-upload-file-popover"
                }
              })], 1)];
            },
            proxy: true
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "form-group row justify-content-center"
        }, [_c('div', {
          staticClass: "col-lg-3 col-md-4"
        }, [_c('button', {
          staticClass: "ticket-submit-btn pull-right",
          attrs: {
            "type": "submit"
          }
        }, [_vm.isSubmit ? _c('b-spinner', {
          staticClass: "custom-spin"
        }) : _vm._e(), _vm._v(_vm._s(_vm.isSubmit ? "Submitting ..." : "Submit") + " ")], 1)])])])];
      }
    }])
  })], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4 col-md-12 no-padding"
  }, [_c('div', {
    staticClass: "ticket-left-bg-wrapper text-center"
  }, [_c('h2', [_vm._v("Having Problems ?")]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" Please fill the form and submit the issue to support team . ")])])]);
}]

export { render, staticRenderFns }