<template>
  <div class="authWrapper">
    <div class="container">
      <div class="row whiteBG-wrap">
        <div class="col-lg-4 col-md-12 no-padding">
          <div class="ticket-left-bg-wrapper text-center">
            <h2>Having Problems ?</h2>
            <p class="mt-2">
              Please fill the form and submit the issue to support team .
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="ticket-form-wrap login-form">
            <div class="auth-form pt-0">
              <div class="d-flex justify-content-between">
                <h2>Submit An Issue</h2>
                <div>
                  <router-link to="/" class="go-home-btn"
                    ><i class="fa fa-home me-2"></i>Go To Home</router-link
                  >
                </div>
              </div>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.stop.prevent="handleSubmit(submit)">
                  <div class="form-group row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        labelFor="first_name"
                        labelName="First Name"
                        inputName="first_name"
                        :vmodel.sync="form.first_name"
                        input-class="shadow-none"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        labelFor="last_name"
                        labelName="Last Name"
                        inputName="last_name"
                        :vmodel.sync="form.last_name"
                        input-class="shadow-none"
                        form-group-class="w-full"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true, email: true }"
                        labelFor="email"
                        labelName="Email"
                        inputName="email"
                        :vmodel.sync="form.email"
                        input-class="shadow-none"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: false }"
                        labelFor="phone"
                        labelName="Phone Number"
                        inputName="phone"
                        :vmodel.sync="form.phone"
                        input-class="shadow-none"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-text
                        :rules="{ required: true }"
                        labelFor="subject"
                        labelName="Subject"
                        inputName="subject"
                        :vmodel.sync="form.subject"
                        input-class="shadow-none"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <input-multi-select
                        :vmodel.sync="form.category_id"
                        label-for="category"
                        labelName="Category"
                        :options="
                          categories.length
                            ? categories.map((item) => item.id)
                            : []
                        "
                        :custom-label="
                          categories.length
                            ? (id) =>
                                categories.find((item) => item.id == id).name
                            : () => {}
                        "
                        :active-data="categories"
                        :rules="{ required: true }"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <input-quill-editor
                        :vmodel.sync="form.message"
                        label="Message"
                        :rules="{ required: true }"
                        placeholder="Add your message here ..."
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <input-file-multiple
                        :vmodel.sync="form.issue_document"
                        label-name="Upload Document"
                        label-for="document"
                        :rules="{ required: false }"
                      >
                        <template #labelMessage>
                          <span
                            class="upload-message"
                            :id="`ticket-upload-files`"
                            ><i class="fa fa-exclamation"></i>
                            <b-popover
                              :target="`ticket-upload-files`"
                              placement="top"
                              triggers="hover focus"
                              :content="`Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt up to 4Mb`"
                              id="ticket-upload-file-popover"
                            ></b-popover>
                          </span>
                        </template>
                      </input-file-multiple>
                    </div>
                  </div>
                  <div class="form-group row justify-content-center">
                    <div class="col-lg-3 col-md-4">
                      <button
                        type="submit"
                        class="ticket-submit-btn pull-right"
                      >
                        <b-spinner class="custom-spin" v-if="isSubmit" />{{
                          isSubmit ? "Submitting ..." : "Submit"
                        }}
                      </button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputFileMultiple from "../components/form/InputFileMultiple.vue";
import InputQuillEditor from "../components/form/InputQuillEditor.vue";
import axios from "axios";
const FORM = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  subject: "",
  message: "",
  user_type: "A", //for rep dashboard
  category_id: "",
  issue_document: [],
};

export default {
  components: { InputQuillEditor, InputFileMultiple },
  name: "TicketSubmission",
  data: () => ({
    form: {
      ...FORM,
    },
    categories: [],
    isSubmit: false,
  }),
  methods: {
    getCategories() {
      let loader = this.$loading.show();
      const url = `${process.env.VUE_APP_TICKETING_URL_API}v1/tickets/category`;
      axios
        .get(url)
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch(() => {
          this.categories = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
    submit() {
      let loader = this.$loading.show();
      const url = `${process.env.VUE_APP_TICKETING_URL_API}v1/tickets/send`;
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
        if (
          key == "issue_document" &&
          this.form.issue_document &&
          this.form.issue_document.length
        ) {
          formData.delete("issue_document"); //reset
          for (let i = 0; i < this.form.issue_document.length; i++) {
            formData.append(`${key}[${i}]`, this.form.issue_document[i]);
          }
        }
      }
      this.isSubmit = true;
      axios
        .post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.resetForm();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
          this.isSubmit = false;
        });
    },
    resetForm() {
      this.form = { ...FORM };
      this.$refs.observer.reset();
    },
  },
  created() {
    this.getCategories();
  },
};
</script>
<style src="@/assets/css/authen.css" scoped></style>
<style>
.ticket-form-wrap label {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 8px !important;
}
#ticket-upload-file-popover {
  border-radius: 8px !important;
  box-shadow: 0 0 8px #dfe8ea !important;
  border: none !important;
  top: -6px !important;
  min-width: 525px !important;
}
.ticket-form-wrap div.multiselect {
  width: 100% !important;
  margin-left: 0 !important;
}
</style>
<style scoped>
.ticket-left-bg-wrapper {
  background: url("~@/assets/images/bg_blue.png") no-repeat;
  height: 100%;
  background-size: cover;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  color: #fff;
}
.ticket-form-wrap {
  padding: 4rem 2rem;
}
.upload-message {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #eee;
  margin: 0px 4px 2px 8px;
  font-size: 13px;
  cursor: pointer;
}
.ticket-submit-btn {
  border: 0;
  background: #0cf;
  color: #fff;
  padding: 10px 13px;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
  transition: all ease 0.6s;
  min-width: 190px;
}
.go-home-btn {
  padding: 10px;
  background: #fff;
  color: #0cf;
  transition: all 0.3s;
  border: 1px solid #0cf;
  border-radius: 8px;
  font-size: 14px;
}
.go-home-btn:hover {
  background: #0cf;
  color: #fff;
}
.custom-spin {
  height: 18px !important;
  width: 18px !important;
  margin-right: 5px;
}
</style>
